var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      id: "sidebar-form-product-kit",
      title: _vm.isKitItemUpdate
        ? _vm.$t("Editar item do kit")
        : _vm.$t("Novo item do kit"),
      show: _vm.showSidebar,
      fetching: _vm.fetching,
      saving: _vm.saving,
      width: "550px",
    },
    on: { save: _vm.onAddKitItem, hidden: _vm.onHideSidebar },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          var _obj
          return [
            _c(
              "FormulateForm",
              { ref: "kitSidebarForm", attrs: { name: "kitSidebarForm" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("e-search-sku", {
                          attrs: {
                            id: "kit_item_sidebar-sku_id",
                            "value-is-object": "",
                          },
                          model: {
                            value: _vm.kitItemForm.skuProduct,
                            callback: function ($$v) {
                              _vm.$set(_vm.kitItemForm, "skuProduct", $$v)
                            },
                            expression: "kitItemForm.skuProduct",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "kit_item_sidebar-quantity",
                            "is-edit": false,
                            type: "text-number",
                            label: _vm.$t("Quantidade"),
                            validation: "required|min:1",
                          },
                          model: {
                            value: _vm.kitItemForm.quantity,
                            callback: function ($$v) {
                              _vm.$set(_vm.kitItemForm, "quantity", _vm._n($$v))
                            },
                            expression: "kitItemForm.quantity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "6" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "kit_item_sidebar-editable_quantity",
                            type: "switch",
                            instruction: _vm.$t(
                              "Quando selecionado, a quantidade deste item poderá ser alterada no caixa."
                            ),
                            label: _vm.$t("Quantidade editável?"),
                          },
                          model: {
                            value: _vm.kitItemForm.quantityIsEditable,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.kitItemForm,
                                "quantityIsEditable",
                                $$v
                              )
                            },
                            expression: "kitItemForm.quantityIsEditable",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "discount-type",
                            type: "radio",
                            options:
                              ((_obj = {}),
                              (_obj[_vm.discountTypesEnum.VALUE] = "R$"),
                              (_obj[_vm.discountTypesEnum.PERCENTAGE] = "%"),
                              _obj),
                            label: _vm.$t("Desconto em"),
                            "element-class": ["d-flex", "mt-1"],
                          },
                          model: {
                            value: _vm.kitItemForm.discountType,
                            callback: function ($$v) {
                              _vm.$set(_vm.kitItemForm, "discountType", $$v)
                            },
                            expression: "kitItemForm.discountType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "kit_item_sidebar-discount_percentage",
                            type: _vm.isDiscountPercent
                              ? "text-percetage"
                              : "text-number",
                            label:
                              _vm.$t("Desconto unit.") +
                              " (" +
                              _vm.getDiscountSymbol +
                              ")",
                            currency: _vm.getDiscountSymbol,
                            "currency-symbol-position": _vm.isDiscountPercent
                              ? "suffix"
                              : "prefix",
                            precision: 2,
                          },
                          model: {
                            value: _vm.kitItemForm.unitDiscount,
                            callback: function ($$v) {
                              _vm.$set(_vm.kitItemForm, "unitDiscount", $$v)
                            },
                            expression: "kitItemForm.unitDiscount",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }